<template>
	<input
		v-if="is_permission"
		type="file"
		class="hide"
		:accept="accept"
		:placeholder="placeholder"
		:capture="capture"
		:multiple="multiple"
		ref="file"
		@change="toChange"
		data-type="1"
	/>
	<input
		v-else
		type="file"
		class="hide"
		:accept="accept"
		:placeholder="placeholder"
		:capture="capture"
		:multiple="multiple"
		ref="file"
		@click="checkPermission"
		data-type="2"
	/>
</template>

<script>
	export default {
		name: 'InputFile'
		, props: ['accept', 'placeholder', 'capture', 'multiple']
		, data: function(){
			return{
				is_permission: false
				, upload_img: {

				}
				, file_upload_code: 'CM00700015'
			}
		}
		, computed: {
			appendix_file_div_code: function(){
				let t = ''
				if(this.accept.indexOf('image') > -1){
					t = 'CA01000001'
				}else if(this.accept.indexOf('video') > -1){
					t = 'CA01000002'
				}else if(this.accept.indexOf('audio') > -1) {
					t = 'CA01000003'
				}
				return t
			}
		}
		, methods: {

			checkPermission: function(){
console.log('checkPermission')
				if(this.is_permission){
					return false
				}
				this.doFile()
			}

			, doFile: function(){
				this.is_permission = true
			}

			, toChange: function(){
				this.postFile()
			}

			, postFile: async function(){
				try{
					// this.$bus.$emit('on', true)
					if(!this.upload_img){
						return true
					}
					let login_info = this.$encodeStorage.getSessionAt()

					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.post_file
						, data: {
							member_number: login_info.member_number
							, file_upload_code: this.file_upload_code
							, appendix_file_div_code: this.appendix_file_div_code
							, appendix_file_name: this.$refs.file.files[0]
						}
						, multipart: true
						, type: true
					})

					if(result.success){
						this.$emit('change', this.$refs.file.files)
					}else{
						throw result.message
					}

				}catch (e) {
					console.log('postFile error', e)
					this.$bus.$emit('notify', { type: 'error', message: e})
					return false
				}finally {
					this.$bus.$emit('on', false)
				}
			}

		}
		, created() {
		}
	}
</script>